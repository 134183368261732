import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
interface CarouselItems {
	items: Array<{
		imgSrc: string;
		h3: string;
		p: Array<string>;
	}>;
	h2: string;
	h3Color: string;
	bgColor: string;
}

const Carousel: React.FC<CarouselItems> = (props: CarouselItems) => {
	SwiperCore.use([Autoplay, Pagination, Navigation]);

	return (
		<section
			className={'px-3 py-20'}
			style={{ backgroundColor: props.bgColor }}
		>
			<div className={'container mx-auto'}>
				<h2 className={'text-center font-rubik text-4xl'}>
					{props.h2}
				</h2>
				<Swiper
					modules={[Navigation, Pagination]}
					spaceBetween={50}
					loop
					className={'max-w-[980px]'}
					navigation
					pagination={{ clickable: true }}
					autoplay={{ delay: 3000 }}
					slidesPerView={1}
				>
					{props.items.map((item) => {
						let pArray =
							typeof item.p === 'object' ? item.p : false;

						return (
							<SwiperSlide>
								<div
									className={
										'flex flex-row py-10 px-20 max-lg:flex-col max-lg:items-center max-lg:px-16'
									}
								>
									<div
										className={
											'flex min-w-[220px] flex-col justify-center px-10 max-lg:w-full max-lg:items-center max-lg:pb-8'
										}
									>
										<img width={220} src={item.imgSrc} />
									</div>
									<div
										className={
											'flex flex-col px-10 pb-6 max-lg:w-full'
										}
									>
										<h3
											className={
												'font-rubik text-4xl font-normal'
											}
											style={{ color: props.h3Color }}
										>
											{item.h3}
										</h3>
										{item.p.map((p) => {
											return (
												<p
													className={
														'mb-0 font-open-sans text-xl text-bt-gray'
													}
												>
													{p === '' ? <br /> : p}
												</p>
											);
										})}
									</div>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</section>
	);
};

export default Carousel;
