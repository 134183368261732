import * as React from 'react';

interface ImageHeroProps {
	bgImage: string;
	h1: string;
}

const ImageHero3: React.FC<ImageHeroProps> = (props: ImageHeroProps) => {
	return (
		<section
			style={{ backgroundImage: `url('${props.bgImage}')` }}
			className={'bg-cover bg-top px-3 py-14'}
		>
			<div
				className={
					'container mx-auto flex h-[800px] flex-col justify-center text-center max-md:h-[450px] max-md:justify-center'
				}
			>
				<h1
					className={
						'mx-auto max-w-5xl font-rubik text-6xl text-white max-md:text-4xl'
					}
				>
					{props.h1}
				</h1>
			</div>
		</section>
	);
};

export default ImageHero3;
