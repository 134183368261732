import * as React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import PageIntroSection from '../../components/PageIntroSection/PageIntroSection';
import ImageCTA from '../../components/ImageCTA/ImageCTA';
import CareerPathMap from '../../components/CareerPathMap/CareerPathMap';
import ImageHero3 from '../../components/ImageHero3/ImageHero3';
import Carousel from '../../components/Carousel/Carousel';

// @ts-ignore
import tireStack from '../../images/tire-stack.png';
// @ts-ignore
import heroImage from '../../images/career-path-hero.png';
// @ts-ignore
import tireLevel1 from '../../images/tire-level-1.png';
// @ts-ignore
import tireLevel2 from '../../images/tire-level-2.png';
// @ts-ignore
import tireLevel3a from '../../images/tire-level-3a.png';
// @ts-ignore
import tireLevel3b from '../../images/tire-level-3b.png';
// @ts-ignore
import tireLevel4 from '../../images/tire-level-4.png';
// @ts-ignore
import tireLevel5 from '../../images/tire-level-5.png';
// @ts-ignore
import autoAlign1 from '../../images/alignment-1.svg';
// @ts-ignore
import autoAlign2 from '../../images/alignment-2.svg';
// @ts-ignore
import management from '../../images/management.svg';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import { graphql, useStaticQuery } from 'gatsby';

const CareerPathPage: React.FC = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	const tireTechCarouselItems = [
		{
			imgSrc: tireLevel1,
			h3: 'Tire Technician Trainee',
			p: [
				'As a Tire Technician Trainee you learn the basics of tire installation, repair and customer service. Each Trainee gets paired with a trainer to stay by their side and tech the processes of inspecting, repairing or replacing tires.'
			]
		},
		{
			imgSrc: tireLevel2,
			h3: 'Tire Technician',
			p: [
				"Once you have learned the basics of tire care and repair, you'll be promoted to an L2 Tire Technician. As an L2, you have mastered all processes and act as a role model for any new Tire Techs that join the team."
			]
		},
		{
			imgSrc: tireLevel3a,
			h3: 'Warehouse Technician',
			p: [
				'As a Warehouse Tire Technician, you focus on the "back of the house" duties of organizing and maintaining the warehouse in addition to standard Tire Tech duties. Warehouse Technicians take the lead in making sure the team is able to quickly locate and pull the right tires for our customers.'
			]
		},
		{
			imgSrc: tireLevel3b,
			h3: 'Tire & Auto Glass Technician',
			p: [
				"As a Tire and Auto Glass Technician, you take on store glass repairs. After completing the necessary training, you'll take responsibility for fixing chips and replacing windshields on the vehicles that enter your store in addition to standard Tire Tech duties."
			]
		},
		{
			imgSrc: tireLevel4,
			h3: 'Tire Technician Trainer',
			p: [
				'As a Tire Technician Trainer, you become the first person a new Tire Technician interacts with. Tire Technician Trainers set the tone for shop expectations and act as the go-to for any questions from the team.'
			]
		},
		{
			imgSrc: tireLevel5,
			h3: 'Tire & Lube Technician',
			p: [
				'In addition to Tire Tech responsibilities, Tire and Lube Technicians provide oil changes and flushes as well as inspect and replace filters, batteries, wiper blades and headlights. This is a great opportunity for someone interested in learning more about the mechanics of vehicles and potentially growing towards becoming an Alignment or Auto Technician.'
			]
		}
	];
	const autoTechCarouselItems = [
		{
			imgSrc: autoAlign1,
			h3: 'Alignment Technician Trainee',
			p: [
				'Permit required (MI)',
				'Partner with ASE certified technician',
				'',
				'Alignment Technician Trainees work side-by-side with a state licensed mechanic to learn the ins and outs of vehicle alignments. This hands-on training experience will prepare you to become an Alignment Technician all while you study for your state license.'
			]
		},
		{
			imgSrc: autoAlign1,
			h3: 'Alignment Technician',
			p: [
				'Perform all alignments perform fluid services',
				'State license Required (MI)',
				'',
				'Using state-of-the-art alignment machines, Alignment Technicians diagnose vehicle needs and perform vehicle alignments. Alignment Technicians also help with oil changes and fluids along with inspection and replacement of filters, batteries, wiper blades and headlights.'
			]
		},
		{
			imgSrc: autoAlign2,
			h3: 'Auto Technician Trainee',
			p: [
				'Permit required (MI)',
				'Partner with ASE certified technician',
				'',
				'Automotive Technician Trainees train side-by-side with a state licensed mechanic to learn the ins and outs of full service auto repair. This hands-on experience will prepare you to become an Auto Technician while you study for your state license.'
			]
		},
		{
			imgSrc: autoAlign2,
			h3: 'Auto Technician',
			p: [
				'Perform mechanical repairs (certified areas)',
				'State license required (MI)',
				'',
				"As an Automotive Technician, you perform full service auto repair. You'll inspect vehicles, educate customers on any issues and provide necessary maintenance to get the car working effectively again. You'll work on brakes, steering wheels, transmissions, engines and electrical systems. You'll also recommend and provide preventative maintenance like oil changes."
			]
		}
	];
	const salesManagementCarouselItems = [
		{
			imgSrc: management,
			h3: 'Sales Associate',
			p: [
				'As a Sales Associate, you answer calls, help customers understand their vehicle repair needs and sell additional services and preventative care. You provide excellent customer service within every customer interaction to create the best first impression.'
			]
		},
		{
			imgSrc: management,
			h3: '3rd Assistant Manager',
			p: [
				"The 3rd Assistant sets the tone for great customer service as the first face customers see when they walk in our doors. You'll strive to achieve advanced sales goals while building a relationship with our customers."
			]
		},
		{
			imgSrc: management,
			h3: '2nd Assistant Manager',
			p: [
				'As a 2nd Assistant, you are part of the first line of customer service for our customers! You answer calls, help customers understand their needs as it relates to their vehicle repairs and sell additional services and preventative care to meet your advanced sales goal. You will set the tone for the initial customer service received by all customers who enter our store.'
			]
		},
		{
			imgSrc: management,
			h3: '1st Assistant Manager',
			p: [
				'As the 1st Assistant, you will not only handle customer service, sales, and communication with the shop regarding repairs, but you will be able to impact your entire team by providing management support to the Store Manager. You will help to solve daily problems for your team and customers.'
			]
		},
		{
			imgSrc: management,
			h3: 'Manager in Training',
			p: [
				'As a Manager in Training, you’ll learn what it takes to run your own Belle Tire store. You’ll partner with the Store Manager to handle customer service, sales and communication among the team. You’ll work to hire, develop and motivate the team all while learning about the structure of our business.'
			]
		},
		{
			imgSrc: management,
			h3: 'Store Manager',
			p: [
				'As Store Manager, you handle customer service, sales, and communication with the shop regarding repairs. You will impact your entire team by resolving daily problems for your team and customers. In addition to being responsible for all store sales, you will motivate employees to increase sales, hire new talent and develop current talent to run an efficient business.'
			]
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<ImageHero3
				bgImage={heroImage}
				h1={'Determine your own Career path with us'}
			/>
			<PageIntroSection
				h2={'BUILDING AND GROWING YOUR CAREER'}
				white
				pTags={[
					{
						text: 'At Belle Tire, we offer a unique program that allows you to move up in your area of specialty or move to other areas with in-house training. We value the knowledge and expertise that you bring to us, but also know that is not always available in your previous path or schooling. We won’t hold that against you! We encourage you to think ahead and we will help you select a path to help you find your ideal career!'
					}
				]}
			/>
			<Carousel
				h2={'TIRE TECHNICIAN'}
				h3Color={'#ff9200'}
				items={tireTechCarouselItems}
				bgColor={'#E9E9E9'}
			/>
			<Carousel
				h2={'AUTO/ALIGNMENT TECHNICIAN'}
				h3Color={'#003da5'}
				items={autoTechCarouselItems}
				bgColor={'#ffffff'}
			/>
			<Carousel
				h2={'SALES/MANAGEMENT'}
				h3Color={'#C8122E'}
				items={salesManagementCarouselItems}
				bgColor={'#E9E9E9'}
			/>
			<JobSearchForm data={data} />

			<JobAlerts />

			{/*<CareerPathMap />*/}
			{/*<ImageCTA*/}
			{/*	imageSrc={tireStack}*/}
			{/*	imageWidth={450}*/}
			{/*	h2={'READY TO GET STARTED?'}*/}
			{/*	p={*/}
			{/*		'Belle Tire is growing and wants you to join our successful team today!'*/}
			{/*	}*/}
			{/*	buttonText={'APPLY NOW'}*/}
			{/*	buttonUrl={'/search/'}*/}
			{/*/>*/}
		</Layout>
	);
};

export default CareerPathPage;
